import Vue from 'vue/dist/vue.min';
import {cookie} from './components/cookie';

export function vm(){
    const v = new Vue({
        el: '#vm',
        components: {
            cookie: cookie()
        }
    });
    const h = new Vue({
        el: '#site-header',
		data: {
			flag: false
		}
    });
};