export function cookie(){
    const temp = {
        data: function(){
            return {
                view: false,
				expire: 365
            }
        },
		methods: {
			cookieSet(){
				this.setWithExpiry('popupFlag', 'false', this.expire);
				this.view = false;
			},
		  	setWithExpiry(key, value, expire){
				const current = new Date();
				expire = current.getTime() + expire * 24 * 3600 * 1000;
				const item = {
					value: value,
					expire: expire
				};
				localStorage.setItem(key, JSON.stringify(item));
			},
		},
		mounted: function(){
			const flag = localStorage.getItem('popupFlag');
			if (flag != null) {
				const data = JSON.parse(flag);
				const current = new Date();
				if (data['value'] == 'false' && current.getTime() < data['expire']) {
					this.view = false;
				}
				else{
					this.view = true;
				}
			}
			else{
				this.view = true;
			}
		},
        template: 
        `
            <div class="cookiebox" id="cookiebox" v-if="view">
				<div class="cookiebox__item">
					<h6>Cookie使用の同意</h6>
					<p>当サイトではCookieを使用しています。Cookieの使用に同意する場合、同意ボタンを選択して下さい。</p>
					<div class="cookiebox__item__btn" @click="cookieSet()">同意する</div>
				</div>
			</div>
        `
    }
    return temp;
};

