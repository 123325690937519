import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
    let indexswipe = document.getElementById('indexswipe');
    if(indexswipe){
        new Swiper(indexswipe, {
            spaceBetween: 0,
            effect: 'fade',
            speed: 2000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        }); 
    }
	
    let sideswipe = document.getElementById('sideswipe');
    if(sideswipe){
        new Swiper(sideswipe, {
            spaceBetween: 0,
            speed: 600,
			loop: true,
            pagination: {
                el: document.getElementById('sidepager'),
				clickable: true,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        }); 
    }

    let slides = document.getElementById('slides');
    if(slides){
        new Swiper(slides, {
            slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			grabCursor: true,
            speed: 600,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
     		navigation: {
          		nextEl: ".swiper-button-next",
          		prevEl: ".swiper-button-prev",
        	},
        }); 
    }

};